import { FC } from 'react';
import RobotModel from '../RobotModel/RobotModel';
import './MyAgentCard.css';

interface MyAgentCardProps {
  id?: string;
  name: string;
  role: string;
  personality: string;
  interactions: string;
  purpose: string;
  status: 'pending' | 'approved';
}

const MyAgentCard: FC<MyAgentCardProps> = ({
  name,
  role,
  personality,
  interactions,
  purpose,
  status
}) => {
  return (
    <div className="my-agent-card" data-status={status}>
      <div className="card-content">
        <div className="model-container">
          <RobotModel status={status} />
        </div>
        <h3 className="agent-name">{name}</h3>
        <div className="agent-details">
          <div className="detail-row">
            <span className="label">Role:</span>
            <span className="value">{role}</span>
          </div>
          <div className="detail-row">
            <span className="label">Personality:</span>
            <span className="value">{personality}</span>
          </div>
          <div className="detail-row">
            <span className="label">Interactions:</span>
            <span className="value">{interactions}</span>
          </div>
          <div className="detail-row">
            <span className="label">Purpose:</span>
            <span className="value purpose">{purpose}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAgentCard;

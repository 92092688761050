import * as THREE from 'three';

function getESTTime() {
  const date = new Date();
  return date.toLocaleTimeString('en-US', {
    timeZone: 'America/New_York',
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });
}

export function createBuilding() {
  const buildingGroup = new THREE.Group();

  // Base building - wider and shorter
  const baseGeometry = new THREE.BoxGeometry(50, 30, 40);
  const baseMaterial = new THREE.MeshStandardMaterial({
    color: 0x111111,
    roughness: 0.2,
    metalness: 0.8
  });
  const base = new THREE.Mesh(baseGeometry, baseMaterial);
  base.position.y = 15;
  buildingGroup.add(base);

  // Entrance doors
  const doorFrameGeometry = new THREE.BoxGeometry(12, 8, 1);
  const doorFrameMaterial = new THREE.MeshStandardMaterial({
    color: 0x222222,
    roughness: 0.2,
    metalness: 0.9
  });
  const doorFrame = new THREE.Mesh(doorFrameGeometry, doorFrameMaterial);
  doorFrame.position.set(0, 4, 20.1);
  buildingGroup.add(doorFrame);

  // Glass doors
  const doorGeometry = new THREE.BoxGeometry(5, 7, 0.2);
  const doorMaterial = new THREE.MeshStandardMaterial({
    color: 0x88ccff,
    roughness: 0.1,
    metalness: 0.9,
    transparent: true,
    opacity: 0.5
  });
  
  // Left door
  const leftDoor = new THREE.Mesh(doorGeometry, doorMaterial);
  leftDoor.position.set(-2.6, 4, 20.2);
  buildingGroup.add(leftDoor);

  // Right door
  const rightDoor = new THREE.Mesh(doorGeometry, doorMaterial);
  rightDoor.position.set(2.6, 4, 20.2);
  buildingGroup.add(rightDoor);

  // Door handles
  const handleGeometry = new THREE.CylinderGeometry(0.1, 0.1, 2, 8);
  const handleMaterial = new THREE.MeshStandardMaterial({
    color: 0xcccccc,
    roughness: 0.2,
    metalness: 0.9
  });

  // Left door handle
  const leftHandle = new THREE.Mesh(handleGeometry, handleMaterial);
  leftHandle.rotation.z = Math.PI / 2;
  leftHandle.position.set(-4.6, 4, 20.3);
  buildingGroup.add(leftHandle);

  // Right door handle
  const rightHandle = new THREE.Mesh(handleGeometry, handleMaterial);
  rightHandle.rotation.z = Math.PI / 2;
  rightHandle.position.set(4.6, 4, 20.3);
  buildingGroup.add(rightHandle);

  // Digital Clock above doors
  const clockCanvas = document.createElement('canvas');
  clockCanvas.width = 512;
  clockCanvas.height = 128;
  const clockTexture = new THREE.CanvasTexture(clockCanvas);
  const clockMaterial = new THREE.MeshBasicMaterial({
    map: clockTexture,
    transparent: true,
    opacity: 1,
    side: THREE.DoubleSide
  });
  const clockGeometry = new THREE.PlaneGeometry(12, 3);
  const clockMesh = new THREE.Mesh(clockGeometry, clockMaterial);
  clockMesh.position.set(0, 9, 20.2); // Positioned above the doors
  buildingGroup.add(clockMesh);

  function updateClock() {
    const ctx = clockCanvas.getContext('2d');
    if (ctx) {
      ctx.fillStyle = '#000000';
      ctx.fillRect(0, 0, 512, 128);
      
      ctx.shadowColor = '#00ffff';
      ctx.shadowBlur = 20;
      
      ctx.font = 'bold 72px monospace';
      ctx.textAlign = 'center';
      ctx.fillStyle = '#00ffff';
      ctx.fillText(getESTTime(), 256, 85);
      
      clockTexture.needsUpdate = true;
    }
  }

  // Windows
  const windowGeometry = new THREE.BoxGeometry(3, 2, 0.1);
  const windowMaterial = new THREE.MeshStandardMaterial({
    color: 0x88ccff,
    emissive: 0x88ccff,
    emissiveIntensity: 0.5,
    transparent: true,
    opacity: 0.9
  });

  // Create windows in a grid pattern
  for (let y = 5; y < 25; y += 4) {
    for (let x = -20; x <= 20; x += 6) {
      // Skip window positions where the door is
      if (y < 8 && Math.abs(x) < 6) continue;
      // Skip window position where the clock is
      if (y === 9 && Math.abs(x) < 6) continue;

      // Front windows
      const frontWindow = new THREE.Mesh(windowGeometry, windowMaterial);
      frontWindow.position.set(x, y, 20.1);
      buildingGroup.add(frontWindow);

      // Back windows
      const backWindow = new THREE.Mesh(windowGeometry, windowMaterial);
      backWindow.position.set(x, y, -20.1);
      buildingGroup.add(backWindow);

      // Side windows
      const leftWindow = new THREE.Mesh(windowGeometry, windowMaterial);
      leftWindow.rotation.y = Math.PI / 2;
      leftWindow.position.set(-25.1, y, x * 0.8);
      buildingGroup.add(leftWindow);

      const rightWindow = new THREE.Mesh(windowGeometry, windowMaterial);
      rightWindow.rotation.y = Math.PI / 2;
      rightWindow.position.set(25.1, y, x * 0.8);
      buildingGroup.add(rightWindow);
    }
  }

  // Building Text
  const textCanvas = document.createElement('canvas');
  textCanvas.width = 1024;
  textCanvas.height = 256;
  const ctx = textCanvas.getContext('2d');
  if (ctx) {
    ctx.fillStyle = '#000000';
    ctx.fillRect(0, 0, 1024, 256);
    ctx.font = 'bold 120px Arial';
    ctx.textAlign = 'center';
    ctx.fillStyle = '#ffffff';
    ctx.fillText('REM', 512, 150);

    // Add glow effect
    ctx.shadowColor = '#00ffff';
    ctx.shadowBlur = 20;
    ctx.fillText('REM', 512, 150);
  }
  const textTexture = new THREE.CanvasTexture(textCanvas);
  const textMaterial = new THREE.MeshBasicMaterial({
    map: textTexture,
    transparent: true,
    opacity: 1,
    side: THREE.DoubleSide
  });
  const textGeometry = new THREE.PlaneGeometry(30, 8);
  const textMesh = new THREE.Mesh(textGeometry, textMaterial);
  textMesh.position.set(0, 25, 20.2);
  buildingGroup.add(textMesh);

  // Red Carpet
  const carpetGeometry = new THREE.PlaneGeometry(15, 50);
  const carpetMaterial = new THREE.MeshStandardMaterial({
    color: 0xcc0000,
    roughness: 0.8,
    metalness: 0.2
  });
  const carpet = new THREE.Mesh(carpetGeometry, carpetMaterial);
  carpet.rotation.x = -Math.PI / 2;
  carpet.position.set(0, 0.1, 45);
  buildingGroup.add(carpet);

  // Edge Lights
  const lightGeometry = new THREE.BoxGeometry(0.5, 30, 0.5);
  const lightMaterial = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    emissive: 0xffffff,
    emissiveIntensity: 0.5
  });

  // Add corner lights
  const corners = [
    [-25.25, 15, -20.25],
    [25.25, 15, -20.25],
    [-25.25, 15, 20.25],
    [25.25, 15, 20.25]
  ];

  corners.forEach(([x, y, z]) => {
    const light = new THREE.Mesh(lightGeometry, lightMaterial);
    light.position.set(x, y, z);
    buildingGroup.add(light);
  });

  // Animation function
  buildingGroup.userData.animate = () => {
    // Window lights flickering
    buildingGroup.children.forEach(child => {
      if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
        if (child.material === windowMaterial) {
          child.material.emissiveIntensity = 0.3 + Math.random() * 0.2;
        }
      }
    });
    
    // Update clock
    updateClock();
  };

  return buildingGroup;
}

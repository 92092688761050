import * as THREE from 'three';

export function createLimbs() {
  const limbsGroup = new THREE.Group();

  // Create Arms
  const createArm = (side: number) => {
    const armGroup = new THREE.Group();

    // Upper arm
    const upperArmGeometry = new THREE.CylinderGeometry(0.04, 0.05, 0.3, 12);
    const armMaterial = new THREE.MeshStandardMaterial({
      color: 0xE0E0E0,
      metalness: 0.8,
      roughness: 0.1
    });
    const upperArm = new THREE.Mesh(upperArmGeometry, armMaterial);
    upperArm.position.y = -0.15;
    armGroup.add(upperArm);

    // Elbow joint
    const elbowGeometry = new THREE.SphereGeometry(0.05, 32, 32);
    const elbowMaterial = new THREE.MeshStandardMaterial({
      color: 0x2196F3,
      metalness: 0.9,
      roughness: 0.1,
      emissive: 0x2196F3,
      emissiveIntensity: 0.3
    });
    const elbow = new THREE.Mesh(elbowGeometry, elbowMaterial);
    elbow.position.y = -0.3;
    armGroup.add(elbow);

    // Forearm
    const forearmGeometry = new THREE.CylinderGeometry(0.05, 0.04, 0.3, 12);
    const forearm = new THREE.Mesh(forearmGeometry, armMaterial);
    forearm.position.y = -0.45;
    armGroup.add(forearm);

    // Hand
    const handGeometry = new THREE.SphereGeometry(0.06, 32, 32);
    const hand = new THREE.Mesh(handGeometry, elbowMaterial);
    hand.position.y = -0.6;
    armGroup.add(hand);

    armGroup.position.set(side * 0.28, 1.4, 0);
    return armGroup;
  };

  const leftArm = createArm(-1);
  const rightArm = createArm(1);
  limbsGroup.add(leftArm);
  limbsGroup.add(rightArm);

  // Create Legs
  const createLeg = (side: number) => {
    const legGroup = new THREE.Group();

    // Upper leg
    const upperLegGeometry = new THREE.CylinderGeometry(0.05, 0.07, 0.4, 12);
    const legMaterial = new THREE.MeshStandardMaterial({
      color: 0xE0E0E0,
      metalness: 0.8,
      roughness: 0.1
    });
    const upperLeg = new THREE.Mesh(upperLegGeometry, legMaterial);
    upperLeg.position.y = 0.65;
    legGroup.add(upperLeg);

    // Knee joint
    const kneeGeometry = new THREE.TorusGeometry(0.06, 0.02, 16, 32);
    const kneeMaterial = new THREE.MeshStandardMaterial({
      color: 0x2196F3,
      metalness: 0.9,
      roughness: 0.1,
      emissive: 0x2196F3,
      emissiveIntensity: 0.5
    });
    const knee = new THREE.Mesh(kneeGeometry, kneeMaterial);
    knee.position.y = 0.45;
    knee.rotation.x = Math.PI / 2;
    legGroup.add(knee);

    // Lower leg
    const lowerLegGeometry = new THREE.CylinderGeometry(0.07, 0.05, 0.4, 12);
    const lowerLeg = new THREE.Mesh(lowerLegGeometry, legMaterial);
    lowerLeg.position.y = 0.25;
    legGroup.add(lowerLeg);

    legGroup.position.set(side * 0.2, 0.15, 0);
    return legGroup;
  };

  const leftLeg = createLeg(-1);
  const rightLeg = createLeg(1);
  limbsGroup.add(leftLeg);
  limbsGroup.add(rightLeg);

  // Store references for animation
  limbsGroup.userData.arms = { left: leftArm, right: rightArm };
  limbsGroup.userData.legs = { left: leftLeg, right: rightLeg };

  return limbsGroup;
}

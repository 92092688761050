export interface Agent {
  id: string;
  name: string;
  role: string;
  personality: string;
  interactions: string;
  purpose: string;
  status: 'pending' | 'approved';
  createdAt: number;
}

const STORAGE_KEY = 'user_agents';
const RATE_LIMIT_KEY = 'agent_creation_timestamps';
const MAX_AGENTS_PER_DAY = 3;
const HOURS_24 = 24 * 60 * 60 * 1000;

const getCreationTimestamps = (): number[] => {
  const stored = localStorage.getItem(RATE_LIMIT_KEY);
  if (!stored) return [];
  try {
    return JSON.parse(stored);
  } catch {
    return [];
  }
};

const addCreationTimestamp = (timestamp: number) => {
  const timestamps = getCreationTimestamps();
  timestamps.push(timestamp);
  localStorage.setItem(RATE_LIMIT_KEY, JSON.stringify(timestamps));
};

const cleanOldTimestamps = () => {
  const timestamps = getCreationTimestamps();
  const now = Date.now();
  const recentTimestamps = timestamps.filter(timestamp => now - timestamp < HOURS_24);
  localStorage.setItem(RATE_LIMIT_KEY, JSON.stringify(recentTimestamps));
  return recentTimestamps;
};

export const canCreateAgent = (): boolean => {
  const recentTimestamps = cleanOldTimestamps();
  return recentTimestamps.length < MAX_AGENTS_PER_DAY;
};

export const getTimeUntilNextAgent = (): number => {
  const timestamps = cleanOldTimestamps();
  if (timestamps.length === 0) return 0;
  
  const oldestTimestamp = Math.min(...timestamps);
  const timeUntilExpiry = HOURS_24 - (Date.now() - oldestTimestamp);
  return Math.max(0, timeUntilExpiry);
};

export const saveAgent = (agent: Omit<Agent, 'id' | 'status' | 'createdAt'>) => {
  if (!canCreateAgent()) {
    const waitTime = getTimeUntilNextAgent();
    const hours = Math.ceil(waitTime / (1000 * 60 * 60));
    throw new Error(`Daily agent creation limit reached. Please wait ${hours} hours before creating another agent.`);
  }

  const timestamp = Date.now();
  const existingAgents = getAgents();
  
  const newAgent: Agent = {
    ...agent,
    id: `agent-${timestamp}`,
    status: 'pending',
    createdAt: timestamp
  };
  
  const updatedAgents = [...existingAgents, newAgent];
  localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedAgents));
  addCreationTimestamp(timestamp);
  return newAgent;
};

export const getAgents = (): Agent[] => {
  const stored = localStorage.getItem(STORAGE_KEY);
  if (!stored) return [];
  try {
    return JSON.parse(stored);
  } catch {
    return [];
  }
};

import * as THREE from 'three';

export function createGround() {
  const groundGeometry = new THREE.PlaneGeometry(1000, 1000);
  const groundMaterial = new THREE.MeshStandardMaterial({
    color: 0x001100,
    roughness: 0.8,
    metalness: 0.2
  });
  const ground = new THREE.Mesh(groundGeometry, groundMaterial);
  ground.rotation.x = -Math.PI / 2;
  ground.receiveShadow = true;

  // Add fog gradient at the edges
  const fogGeometry = new THREE.PlaneGeometry(1000, 1000);
  const fogTexture = createFogTexture();
  const fogMaterial = new THREE.MeshBasicMaterial({
    map: fogTexture,
    transparent: true,
    opacity: 0.7,
    depthWrite: false
  });
  const fog = new THREE.Mesh(fogGeometry, fogMaterial);
  fog.rotation.x = -Math.PI / 2;
  fog.position.y = 0.1;

  const groundGroup = new THREE.Group();
  groundGroup.add(ground);
  groundGroup.add(fog);
  return groundGroup;
}

function createFogTexture() {
  const canvas = document.createElement('canvas');
  canvas.width = 1024;
  canvas.height = 1024;
  const ctx = canvas.getContext('2d');
  if (ctx) {
    const gradient = ctx.createRadialGradient(512, 512, 0, 512, 512, 512);
    gradient.addColorStop(0, 'rgba(0, 0, 0, 0)');
    gradient.addColorStop(0.5, 'rgba(0, 0, 0, 0.2)');
    gradient.addColorStop(1, 'rgba(0, 0, 0, 0.8)');
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, 1024, 1024);
  }
  const texture = new THREE.CanvasTexture(canvas);
  texture.needsUpdate = true;
  return texture;
}

export function createStars() {
  const starGeometry = new THREE.BufferGeometry();
  const starMaterial = new THREE.PointsMaterial({
    color: 0xffffff,
    size: 0.5,
    transparent: true,
    opacity: 0.8,
    sizeAttenuation: false
  });

  const starVertices = [];
  for (let i = 0; i < 5000; i++) {
    const x = (Math.random() - 0.5) * 2000;
    const y = Math.random() * 1000;
    const z = (Math.random() - 0.5) * 2000;
    starVertices.push(x, y, z);
  }

  starGeometry.setAttribute('position', new THREE.Float32BufferAttribute(starVertices, 3));
  return new THREE.Points(starGeometry, starMaterial);
}

export function createMoon() {
  const moonGeometry = new THREE.SphereGeometry(50, 32, 32);
  const moonMaterial = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    roughness: 0.5,
    metalness: 0,
    emissive: 0x444444,
    emissiveIntensity: 0.2
  });
  const moon = new THREE.Mesh(moonGeometry, moonMaterial);
  moon.position.set(-100, 200, -200);
  return moon;
}

export function createForest() {
  const forestGroup = new THREE.Group();
  const treeCount = 50;
  const spread = 200;

  for (let i = 0; i < treeCount; i++) {
    const x = (Math.random() - 0.5) * spread;
    const z = (Math.random() - 0.5) * spread;
    // Don't place trees near the building or red carpet
    if (Math.abs(x) < 40 && z < 50) continue;
    
    const tree = createTree(x, z);
    tree.scale.setScalar(0.5 + Math.random() * 1);
    tree.rotation.y = Math.random() * Math.PI * 2;
    forestGroup.add(tree);
  }

  return forestGroup;
}

function createTree(x: number, z: number) {
  const treeGroup = new THREE.Group();

  // Tree trunk
  const trunkGeometry = new THREE.CylinderGeometry(1, 1.5, 8, 8);
  const trunkMaterial = new THREE.MeshStandardMaterial({
    color: 0x4d2926,
    roughness: 0.9
  });
  const trunk = new THREE.Mesh(trunkGeometry, trunkMaterial);
  trunk.position.y = 4;
  treeGroup.add(trunk);

  // Tree leaves
  const leavesGeometry = new THREE.ConeGeometry(5, 12, 8);
  const leavesMaterial = new THREE.MeshStandardMaterial({
    color: 0x0d5302,
    roughness: 0.8
  });
  const leaves = new THREE.Mesh(leavesGeometry, leavesMaterial);
  leaves.position.y = 12;
  treeGroup.add(leaves);

  treeGroup.position.set(x, 0, z);
  return treeGroup;
}

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Agents from './pages/Agents';
import MyAgents from './pages/MyAgents';
import Whitepaper from './pages/Whitepaper';
import Verse from './pages/Verse';
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/agents" element={<Agents />} />
        <Route path="/my-agents" element={<MyAgents />} />
        <Route path="/whitepaper" element={<Whitepaper />} />
        <Route path="/verse" element={<Verse />} />
      </Routes>
    </Router>
  );
}

export default App;

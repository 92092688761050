import { FC, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { createRobot } from '../Verse/Robot';
import './RobotModel.css';

interface RobotModelProps {
  status?: 'pending' | 'approved';
}

const RobotModel: FC<RobotModelProps> = ({ status }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    // Scene setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(200, 200);
    containerRef.current.appendChild(renderer.domElement);

    // Lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(5, 5, 5);
    scene.add(directionalLight);

    // Add robot
    const robot = createRobot();
    robot.scale.set(2, 2, 2);
    scene.add(robot);

    // Position camera
    camera.position.z = 3;
    camera.position.y = 2;
    camera.lookAt(0, 1, 0);

    // Animation
    function animate() {
      requestAnimationFrame(animate);
      
      if (robot.userData.animate) {
        robot.userData.animate();
      }
      
      renderer.render(scene, camera);
    }
    animate();

    // Cleanup
    return () => {
      containerRef.current?.removeChild(renderer.domElement);
      renderer.dispose();
    };
  }, []);

  return (
    <div className="robot-model" data-status={status}>
      <div ref={containerRef} className="robot-canvas"></div>
    </div>
  );
};

export default RobotModel;

import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MyAgentCard from '../components/MyAgentCard/MyAgentCard';
import { getAgents, type Agent } from '../store/agents';
import '../styles/MyAgents.css';

const MyAgents = () => {
  const [agents, setAgents] = useState<Agent[]>([]);

  useEffect(() => {
    const loadAgents = () => {
      const userAgents = getAgents();
      setAgents(userAgents);
    };

    loadAgents();
    window.addEventListener('storage', loadAgents);
    return () => window.removeEventListener('storage', loadAgents);
  }, []);

  return (
    <div className="my-agents-wrapper">
      <div className="my-agents-container">
        <Link to="/agents" className="back-link">← Back</Link>
        
        <div className="my-agents-header">
          <h1>My Agents</h1>
          <div className="status-legend">
            <span>Under Review</span>
            <span>Active</span>
          </div>
        </div>

        <div className="my-agents-grid">
          {agents.map(agent => (
            <MyAgentCard
              key={agent.id}
              id={agent.id}
              name={agent.name}
              role={agent.role}
              personality={agent.personality}
              interactions={agent.interactions}
              purpose={agent.purpose}
              status={agent.status}
            />
          ))}
        </div>

        {agents.length === 0 && (
          <div className="no-agents">
            <p>You haven't created any agents yet.</p>
            <Link to="/agents" className="create-link">Create your first agent</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyAgents;

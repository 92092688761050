import * as THREE from 'three';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { Snow } from './Snow';
import { createBuilding } from './Buildings';
import { createGround, createStars, createMoon, createForest } from './Environment';

export function createCamera() {
  const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
  camera.position.set(0, 40, 80);
  return camera;
}

export function createRenderer() {
  const renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  return renderer;
}

export function createLighting(scene: THREE.Scene) {
  // Ambient light for base illumination
  const ambientLight = new THREE.AmbientLight(0x111111, 0.5);
  scene.add(ambientLight);

  // Main directional light (moonlight)
  const moonLight = new THREE.DirectionalLight(0xaaaaff, 1);
  moonLight.position.set(-100, 200, -200);
  moonLight.castShadow = true;
  moonLight.shadow.mapSize.width = 2048;
  moonLight.shadow.mapSize.height = 2048;
  moonLight.shadow.camera.near = 0.5;
  moonLight.shadow.camera.far = 500;
  moonLight.shadow.camera.left = -100;
  moonLight.shadow.camera.right = 100;
  moonLight.shadow.camera.top = 100;
  moonLight.shadow.camera.bottom = -100;
  scene.add(moonLight);

  // Add spotlights to illuminate the text
  const textSpotlight = new THREE.SpotLight(0xffffff, 2);
  textSpotlight.position.set(0, 40, 30);
  textSpotlight.target.position.set(0, 25, 20);
  textSpotlight.angle = Math.PI / 6;
  textSpotlight.penumbra = 0.2;
  scene.add(textSpotlight);
  scene.add(textSpotlight.target);

  // Add spotlights for the entrance doors
  const doorLights = [
    { pos: [-6, 10, 25], target: [-2.6, 4, 20.2] },
    { pos: [6, 10, 25], target: [2.6, 4, 20.2] }
  ];

  doorLights.forEach(({ pos, target }) => {
    const light = new THREE.SpotLight(0xffffff, 1.5);
    light.position.set(pos[0], pos[1], pos[2]);
    light.target.position.set(target[0], target[1], target[2]);
    light.angle = Math.PI / 8;
    light.penumbra = 0.3;
    light.distance = 30;
    light.decay = 1;
    scene.add(light);
    scene.add(light.target);
  });

  // Add some accent lights around the building
  const accentLights = [
    { pos: [30, 5, 30], color: 0x4444ff },
    { pos: [-30, 5, 30], color: 0x4444ff },
    { pos: [30, 5, -30], color: 0x4444ff },
    { pos: [-30, 5, -30], color: 0x4444ff }
  ];

  accentLights.forEach(({ pos, color }) => {
    const light = new THREE.PointLight(color, 1, 50);
    light.position.set(pos[0], pos[1], pos[2]);
    scene.add(light);
  });
}

export function createScene(container: HTMLDivElement) {
  const scene = new THREE.Scene();
  scene.background = new THREE.Color(0x000011);
  scene.fog = new THREE.FogExp2(0x000011, 0.002);

  const camera = createCamera();
  const renderer = createRenderer();
  container.appendChild(renderer.domElement);

  // Controls
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;
  controls.dampingFactor = 0.05;
  controls.minDistance = 30;
  controls.maxDistance = 150;
  controls.maxPolarAngle = Math.PI / 2 - 0.1;

  // Lighting
  createLighting(scene);

  // Add environment
  scene.add(createGround());
  scene.add(createStars());
  scene.add(createMoon());
  scene.add(createForest());

  // Add building
  const building = createBuilding();
  scene.add(building);

  // Add snow
  const snow = new Snow(scene);

  // Animation
  function animate() {
    requestAnimationFrame(animate);

    // Update controls
    controls.update();

    // Animate snow
    snow.update();

    // Animate building
    if (building.userData.animate) {
      building.userData.animate();
    }

    // Render
    renderer.render(scene, camera);
  }

  // Handle window resize
  function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  }

  window.addEventListener('resize', onWindowResize);

  // Set initial camera position and target
  camera.position.set(0, 40, 80);
  controls.target.set(0, 15, 0);
  controls.update();

  // Start animation
  animate();

  return {
    scene,
    camera,
    renderer,
    controls,
    cleanup: () => {
      window.removeEventListener('resize', onWindowResize);
      snow.dispose();
      renderer.dispose();
    }
  };
}

import * as THREE from 'three';

export function createBody() {
  const bodyGroup = new THREE.Group();

  // Torso
  const torsoGeometry = new THREE.CylinderGeometry(0.2, 0.25, 0.5, 12);
  const torsoMaterial = new THREE.MeshStandardMaterial({
    color: 0xE0E0E0,
    metalness: 0.8,
    roughness: 0.1
  });
  const torso = new THREE.Mesh(torsoGeometry, torsoMaterial);
  torso.position.y = 1.2;
  bodyGroup.add(torso);

  // Chest details
  const createChestDetail = (y: number, scale: number) => {
    const detailGeometry = new THREE.PlaneGeometry(0.3 * scale, 0.02);
    const detailMaterial = new THREE.MeshStandardMaterial({
      color: 0x2196F3,
      emissive: 0x2196F3,
      emissiveIntensity: 0.7,
      side: THREE.DoubleSide
    });
    const detail = new THREE.Mesh(detailGeometry, detailMaterial);
    detail.position.set(0, y, 0.13);
    return detail;
  };

  bodyGroup.add(createChestDetail(1.3, 1));
  bodyGroup.add(createChestDetail(1.2, 0.8));
  bodyGroup.add(createChestDetail(1.1, 0.6));

  // Neck
  const neckGeometry = new THREE.CylinderGeometry(0.08, 0.1, 0.15, 32);
  const neckMaterial = new THREE.MeshStandardMaterial({
    color: 0x2196F3,
    metalness: 0.9,
    roughness: 0.1,
    emissive: 0x2196F3,
    emissiveIntensity: 0.3
  });
  const neck = new THREE.Mesh(neckGeometry, neckMaterial);
  neck.position.y = 1.45;
  bodyGroup.add(neck);

  // Hip section
  const hipGeometry = new THREE.CylinderGeometry(0.25, 0.2, 0.15, 12);
  const hipMaterial = new THREE.MeshStandardMaterial({
    color: 0x2C2C2C,
    metalness: 0.9,
    roughness: 0.1
  });
  const hip = new THREE.Mesh(hipGeometry, hipMaterial);
  hip.position.y = 0.9;
  bodyGroup.add(hip);

  return bodyGroup;
}

import { FC } from 'react';
import RobotModel from '../RobotModel/RobotModel';
import './AgentCard.css';

interface AgentProps {
  name: string;
  role: string;
  personality?: string;
  interactions?: string;
  status?: 'pending' | 'approved';
}

const AgentCard: FC<AgentProps> = ({ name, role, personality, interactions, status }) => {
  return (
    <div className="agent-card" data-status={status}>
      <div className="robot-container">
        <RobotModel status={status} />
      </div>
      <div className="agent-info">
        <h2 className="agent-name">{name}</h2>
        <div className="agent-details">
          <p><span className="label">Role:</span><span className="value">{role}</span></p>
          {personality && <p><span className="label">Personality:</span><span className="value">{personality}</span></p>}
          {interactions && <p><span className="label">Interactions:</span><span className="value">{interactions}</span></p>}
        </div>
      </div>
      {status && <div className="status-indicator" />}
    </div>
  );
};

export default AgentCard;

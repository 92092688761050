import * as THREE from 'three';
import { createHead } from './RobotParts/Head';
import { createBody } from './RobotParts/Body';
import { createLimbs } from './RobotParts/Limbs';

export function createRobot() {
  const robotGroup = new THREE.Group();

  // Add all robot parts
  const head = createHead();
  const body = createBody();
  const limbs = createLimbs();

  robotGroup.add(head);
  robotGroup.add(body);
  robotGroup.add(limbs);

  // Scale down the entire robot and move it much lower
  robotGroup.scale.set(0.6, 0.6, 0.6);
  robotGroup.position.y = -0.8; // Moved much lower

  // Animation
  let time = 0;
  robotGroup.userData.animate = () => {
    time += 0.02;

    // Smooth hovering around the lower position
    robotGroup.position.y = -0.8 + Math.sin(time * 0.8) * 0.02;

    // Enhanced head animations
    head.rotation.y = Math.sin(time * 0.5) * 0.08;
    
    // Visor and accent light pulsing
    const intensity = 0.8 + Math.sin(time * 2) * 0.2;
    head.userData.materials.visor.emissiveIntensity = intensity;
    head.userData.materials.leftEye.ring.emissiveIntensity = intensity;
    head.userData.materials.rightEye.ring.emissiveIntensity = intensity;
    head.userData.materials.leftEye.inner.emissiveIntensity = intensity * 0.8;
    head.userData.materials.rightEye.inner.emissiveIntensity = intensity * 0.8;
    head.userData.materials.leftAccent.emissiveIntensity = intensity * 0.8;
    head.userData.materials.rightAccent.emissiveIntensity = intensity * 0.8;

    // Arm animations
    const arms = limbs.userData.arms;
    arms.left.rotation.z = Math.sin(time * 0.8) * 0.05;
    arms.right.rotation.z = -Math.sin(time * 0.8) * 0.05;
  };

  return robotGroup;
}

import { Link } from 'react-router-dom';
import RobotModel from '../components/RobotModel/RobotModel';
import '../styles/Whitepaper.css';

const Whitepaper = () => {
  return (
    <div className="whitepaper-wrapper">
      <div className="whitepaper-container">
        <Link to="/" className="back-link">← Back</Link>
        
        <div className="whitepaper-header">
          <div className="header-model">
            <RobotModel status="approved" />
          </div>
          <h1>REM</h1>
          <p className="subtitle">Random Agent Selection Process</p>
        </div>

        <div className="whitepaper-content">
          <section>
            <h2>The Pool</h2>
            <p>Users submit their custom AI agents to the REM pool (3 per day). Each agent is unique, with its own personality, role, and purpose created using Arc and ClineAI.</p>
          </section>

          <section>
            <h2>Random Selection</h2>
            <p>Using random.org's true randomness generation through atmospheric noise, one agent is randomly selected from the pool.</p>
          </section>

          <section>
            <h2>Activation</h2>
            <p>The chosen agent is brought to life in the REM ecosystem, ready to fulfill its designed purpose and interact with users.</p>
          </section>

          <div className="world-entry">
            <p>Watch the selected agent walk out of REM Inc doors and build a life based on his/her persona.</p>
            <Link to="/verse" className="enter-world-button">
              verse
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whitepaper;

import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { createScene } from '../components/Verse/Scene';
import '../styles/Verse.css';

const Verse = () => {
  const mountRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mountRef.current) return;

    // Create scene and get all necessary components
    const { cleanup } = createScene(mountRef.current);

    // Cleanup
    return cleanup;
  }, []);

  return (
    <>
      <div ref={mountRef} className="verse-container" />
      <Link to="/" className="verse-back-button">← Back</Link>
    </>
  );
};

export default Verse;

import { FC } from 'react';
import RobotModel from '../RobotModel/RobotModel';
import './AgentPreview.css';

interface AgentPreviewProps {
  name: string;
  role: string;
  personality: string;
  interactions: string;
  purpose: string;
  onClose: () => void;
}

const AgentPreview: FC<AgentPreviewProps> = ({
  name,
  role,
  personality,
  interactions,
  purpose,
  onClose
}) => {
  return (
    <div className="agent-preview-overlay">
      <div className="agent-preview-modal">
        <button className="close-button" onClick={onClose}>×</button>
        <h2>Agent Preview</h2>
        <div className="preview-content">
          <div className="preview-model">
            <RobotModel status="pending" />
          </div>
          <div className="preview-field">
            <label>Name:</label>
            <p>{name}</p>
          </div>
          <div className="preview-field">
            <label>Role:</label>
            <p>{role}</p>
          </div>
          <div className="preview-field">
            <label>Personality:</label>
            <p>{personality}</p>
          </div>
          <div className="preview-field">
            <label>Interactions:</label>
            <p>{interactions}</p>
          </div>
          <div className="preview-field">
            <label>Purpose:</label>
            <p>{purpose}</p>
          </div>
          <div className="preview-field">
            <label>Status:</label>
            <p className="status pending">Under Review</p>
          </div>
        </div>
        <div className="preview-message">
          <p>Your agent has been submitted!</p>
          <p>You can view it in "My Agents"</p>
        </div>
      </div>
    </div>
  );
};

export default AgentPreview;

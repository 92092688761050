import * as THREE from 'three';

export class Snow {
  private particles: THREE.Points;
  private particleCount: number = 5000;
  private geometry: THREE.BufferGeometry;
  private material: THREE.PointsMaterial;

  constructor(scene: THREE.Scene) {
    this.geometry = new THREE.BufferGeometry();
    const positions = new Float32Array(this.particleCount * 3);

    for (let i = 0; i < this.particleCount * 3; i += 3) {
      positions[i] = Math.random() * 400 - 200;     // x
      positions[i + 1] = Math.random() * 200;       // y
      positions[i + 2] = Math.random() * 400 - 200; // z
    }

    this.geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));

    this.material = new THREE.PointsMaterial({
      color: 0xffffff,
      size: 0.5,
      transparent: true,
      opacity: 0.8,
      map: this.createSnowflakeTexture()
    });

    this.particles = new THREE.Points(this.geometry, this.material);
    scene.add(this.particles);
  }

  private createSnowflakeTexture(): THREE.Texture {
    const canvas = document.createElement('canvas');
    canvas.width = 16;
    canvas.height = 16;

    const context = canvas.getContext('2d');
    if (!context) throw new Error('Could not get canvas context');

    context.fillStyle = '#ffffff';
    context.beginPath();
    context.arc(8, 8, 4, 0, Math.PI * 2, false);
    context.fill();

    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;
    return texture;
  }

  update() {
    const positions = this.geometry.attributes.position.array as Float32Array;
    for (let i = 0; i < positions.length; i += 3) {
      // Move snowflake down
      positions[i + 1] -= 0.2;

      // If snowflake is too low, reset it to the top
      if (positions[i + 1] < 0) {
        positions[i + 1] = 200;
      }

      // Add slight horizontal movement
      positions[i] += Math.sin(Date.now() * 0.001 + i) * 0.02;
      positions[i + 2] += Math.cos(Date.now() * 0.001 + i) * 0.02;
    }
    this.geometry.attributes.position.needsUpdate = true;
  }

  dispose() {
    this.geometry.dispose();
    this.material.dispose();
    (this.material.map as THREE.Texture)?.dispose();
  }
}

import { useState } from 'react';
import AgentPreview from '../AgentPreview/AgentPreview';
import { saveAgent } from '../../store/agents';
import './CreateAgentForm.css';

interface CreateAgentFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

const ROLES = ['Scout', 'Defender', 'Engineer', 'Medic', 'Scavenger'];
const PERSONALITIES = ['Tactical', 'Resourceful', 'Protective', 'Analytical', 'Adaptive'];
const INTERACTIONS = ['Exploration', 'Defense', 'Construction', 'Healing', 'Resource Gathering'];

const CreateAgentForm = ({ onClose, onSuccess }: CreateAgentFormProps) => {
  const [formData, setFormData] = useState({
    name: '',
    role: '',
    personality: '',
    interactions: '',
    purpose: ''
  });

  const [showPreview, setShowPreview] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    try {
      saveAgent(formData);
      setShowPreview(true);
      setError(null);
      onSuccess();
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    }
  };

  if (showPreview) {
    return (
      <AgentPreview
        {...formData}
        onClose={onClose}
      />
    );
  }

  return (
    <div className="create-agent-overlay">
      <div className="create-agent-modal">
        <button className="close-button" onClick={onClose}>×</button>
        <h2>Create New Agent</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Agent Name</label>
            <input
              type="text"
              id="name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              required
              placeholder="Enter agent name"
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label htmlFor="role">Role</label>
            <select
              id="role"
              value={formData.role}
              onChange={(e) => setFormData({ ...formData, role: e.target.value })}
              required
              className="form-select"
            >
              <option value="" disabled>Select a role</option>
              {ROLES.map(role => (
                <option key={role} value={role} className="form-option">{role}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="personality">Personality</label>
            <select
              id="personality"
              value={formData.personality}
              onChange={(e) => setFormData({ ...formData, personality: e.target.value })}
              required
              className="form-select"
            >
              <option value="" disabled>Select a personality</option>
              {PERSONALITIES.map(personality => (
                <option key={personality} value={personality} className="form-option">{personality}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="interactions">Interactions</label>
            <select
              id="interactions"
              value={formData.interactions}
              onChange={(e) => setFormData({ ...formData, interactions: e.target.value })}
              required
              className="form-select"
            >
              <option value="" disabled>Select interactions</option>
              {INTERACTIONS.map(interaction => (
                <option key={interaction} value={interaction} className="form-option">{interaction}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="purpose">Purpose</label>
            <textarea
              id="purpose"
              value={formData.purpose}
              onChange={(e) => setFormData({ ...formData, purpose: e.target.value })}
              required
              placeholder="Describe how your agent contributes to survival and community well-being"
              rows={4}
              className="form-textarea"
            />
          </div>

          <button type="submit" className="submit-button">Submit Agent</button>
        </form>
      </div>
    </div>
  );
};

export default CreateAgentForm;

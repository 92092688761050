import * as THREE from 'three';

export function createHead() {
  const headGroup = new THREE.Group();
  headGroup.position.y = 1.6;
  headGroup.position.z = 0.2; // Moved forward for better visibility

  // Main head - larger and more forward
  const mainHeadGeometry = new THREE.BoxGeometry(0.4, 0.3, 0.35);
  const mainHeadMaterial = new THREE.MeshStandardMaterial({
    color: 0xE0E0E0,
    metalness: 0.8,
    roughness: 0.1
  });
  const mainHead = new THREE.Mesh(mainHeadGeometry, mainHeadMaterial);
  mainHead.position.z = 0.1; // Offset forward
  headGroup.add(mainHead);

  // Front face plate - more prominent
  const facePlateGeometry = new THREE.BoxGeometry(0.38, 0.28, 0.1);
  const facePlateMaterial = new THREE.MeshStandardMaterial({
    color: 0x2C2C2C,
    metalness: 0.9,
    roughness: 0.1
  });
  const facePlate = new THREE.Mesh(facePlateGeometry, facePlateMaterial);
  facePlate.position.z = 0.2;
  headGroup.add(facePlate);

  // Large visor - more prominent and higher contrast
  const visorGeometry = new THREE.BoxGeometry(0.36, 0.15, 0.05);
  const visorMaterial = new THREE.MeshStandardMaterial({
    color: 0x2196F3,
    emissive: 0x2196F3,
    emissiveIntensity: 1,
    metalness: 1,
    roughness: 0
  });
  const visor = new THREE.Mesh(visorGeometry, visorMaterial);
  visor.position.z = 0.25;
  visor.position.y = 0.02;
  headGroup.add(visor);

  // Additional eye details for depth
  const createEye = (x: number) => {
    const eyeGroup = new THREE.Group();
    
    // Outer ring
    const ringGeometry = new THREE.RingGeometry(0.04, 0.06, 32);
    const ringMaterial = new THREE.MeshStandardMaterial({
      color: 0x2196F3,
      emissive: 0x2196F3,
      emissiveIntensity: 1,
      side: THREE.DoubleSide
    });
    const ring = new THREE.Mesh(ringGeometry, ringMaterial);
    
    // Inner eye
    const innerGeometry = new THREE.CircleGeometry(0.04, 32);
    const innerMaterial = new THREE.MeshStandardMaterial({
      color: 0x64B5F6,
      emissive: 0x64B5F6,
      emissiveIntensity: 0.8,
      side: THREE.DoubleSide
    });
    const inner = new THREE.Mesh(innerGeometry, innerMaterial);
    inner.position.z = -0.001;
    
    eyeGroup.add(ring);
    eyeGroup.add(inner);
    eyeGroup.position.set(x, 0.02, 0.28);
    return { group: eyeGroup, materials: { ring: ringMaterial, inner: innerMaterial } };
  };

  const leftEye = createEye(-0.1);
  const rightEye = createEye(0.1);
  headGroup.add(leftEye.group);
  headGroup.add(rightEye.group);

  // Top armor plate
  const topPlateGeometry = new THREE.BoxGeometry(0.42, 0.05, 0.3);
  const topPlateMaterial = new THREE.MeshStandardMaterial({
    color: 0x2C2C2C,
    metalness: 0.9,
    roughness: 0.1
  });
  const topPlate = new THREE.Mesh(topPlateGeometry, topPlateMaterial);
  topPlate.position.y = 0.15;
  topPlate.position.z = 0.1;
  headGroup.add(topPlate);

  // Side armor plates
  const sidePlateGeometry = new THREE.BoxGeometry(0.05, 0.25, 0.25);
  const leftPlate = new THREE.Mesh(sidePlateGeometry, topPlateMaterial);
  leftPlate.position.set(-0.2, 0, 0.1);
  headGroup.add(leftPlate);

  const rightPlate = new THREE.Mesh(sidePlateGeometry, topPlateMaterial);
  rightPlate.position.set(0.2, 0, 0.1);
  headGroup.add(rightPlate);

  // Blue accent lights
  const createAccent = (x: number) => {
    const accentGeometry = new THREE.PlaneGeometry(0.02, 0.15);
    const accentMaterial = new THREE.MeshStandardMaterial({
      color: 0x2196F3,
      emissive: 0x2196F3,
      emissiveIntensity: 0.8,
      side: THREE.DoubleSide
    });
    const accent = new THREE.Mesh(accentGeometry, accentMaterial);
    accent.position.set(x, 0, 0.25);
    return { mesh: accent, material: accentMaterial };
  };

  const leftAccent = createAccent(-0.19);
  const rightAccent = createAccent(0.19);
  headGroup.add(leftAccent.mesh);
  headGroup.add(rightAccent.mesh);

  // Store materials for animation
  headGroup.userData.materials = {
    visor: visorMaterial,
    leftEye: leftEye.materials,
    rightEye: rightEye.materials,
    leftAccent: leftAccent.material,
    rightAccent: rightAccent.material
  };

  return headGroup;
}
